body {
  background: #000;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.bottomBar {
  display: flex;
  width: 100%;
  position: relative;
}

.bottomBar ul {
  list-style: none;
  display: flex;
  flex-direction: row;
  background: #00ffff;
  width: 38%;
  justify-content: space-around;
  position: fixed;
  bottom: 0;
  margin: 0;
  padding: 0;
}

.bottomBar ul li {
  text-align: center;
  width: 50%;
  font-size: 25px;
  color: #000;
  height: 60px;
  line-height: 60px;
  cursor: pointer;
}

.bottomBar ul li.active {
  color: #00ffff;
  background: #fff;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

p {
  margin: 3px;
}

.contentContainer {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  background-color: #000;
  color: #fff;
  width: 40%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  height: 100%;
  position: fixed;
  top: 0px;
  overflow-y: scroll;
}

@media only screen and (max-width: 600px) {
  .contentContainer {
    width: 99%;
  }
  .bottomBar ul {
    width: 99%;
  }
  .bottomBar ul li {
    font-size: 15px;
  }
}
@media only screen and (min-width: 600px) {
  .contentContainer {
    width: 60%;
  }
  .bottomBar ul {
    width: 59%;
  }
}
@media only screen and (min-width: 1000px) {
  .contentContainer {
    width: 40%;
  }
  .bottomBar ul {
    width: 39%;
  }
}

.appFlex {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.appFlex::-webkit-scrollbar {
  display: none;
}

.contentContainer::-webkit-scrollbar {
  display: none;
}

.qrReader {
  height: 100%;
  width: 100%;
  top: 0;
  display: flex;
  position: absolute;
  justify-content: center;
  align-content: center;
  justify-self: center;
  justify-items: center;
  align-items: center;
  background: #fafafa60;
  z-index: 99;
}

.datePicker {
  height: 60px;
  width: 200px;
  font-size: 20px;
  text-align: center;
  line-height: 60px;
}
